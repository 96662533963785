import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export default function Thankyou() {
  return (
    <Layout>
      <Wrapper>
        <h1>All done!</h1>
        <p>Thank you. We have your details and will be in contact shortly.</p>
        <Link to="/">Return Home</Link>
      </Wrapper>
    </Layout>
  )
}